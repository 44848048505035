export default {
  // 版本
  version: '1.1.0',
  // 路径
  // 生产环境
  baseURL: 'https://test.imagetru3d.com', // 生产云管理平台接口
  cloudURl: 'https://cloud-service.zx-real.com', // 生产云管理平台接口
  // 本地服务
  // baseURL: 'http://192.168.1.230:8081', // 对外服务接口
  // cloudURl: 'http://192.168.1.230:8090', // 云管理平台接口
  // 预生产环境代码
  // baseURL: 'https://test-content-service.zx-real.com', // 预生产对外服务接口
  // cloudURl: 'https://test-cloud-service.zx-real.com', // 预生产云管理平台接口
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
