import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Secondary from '@/layout/secondary.vue'
/* import index from './modules' */

import factory from './modules/factory'
import content from './modules/content'
import Main from '@/layout/main.vue'

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location: any) {
  return Promise.resolve(routerRePush.call(this, location)).catch((error: any) => error)
}
Vue.use(VueRouter)

export const asyncRoutes: Array<RouteConfig> = [
  /* ...index, // 首页 */

  ...factory, // 工厂
  ...content// 素材
]

const constantRoutes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/error/404'
  },
  {
    path: '/account',
    name: 'account',
    component: Secondary,
    redirect: '/account/login',
    children: [
      {
        path: '/account/login',
        name: 'login',
        meta: {
          title: '登录',
          parentPath: '/account'
        },
        component: () => import('@/views/account/login.vue')
      }, {
        path: '/account/sign_up',
        name: 'register',
        meta: {
          title: '注册账号',
          parentPath: '/account'
        },
        component: () => import('@/views/account/sign_up.vue')
      },
      {
        path: '/account/find',
        name: 'forget',
        meta: {
          title: '忘记密码',
          parentPath: '/account'
        },
        component: () => import('@/views/account/find.vue')
      },
      {
        path: '/account/private',
        name: 'private',
        meta: {
          title: '隐私政策',
          parentPath: '/account'
        },
        component: () => import('@/views/account/private.vue')
      }
    ]
  },
  {
    path: '/error',
    name: 'error',
    component: Secondary,
    redirect: '/error/404',
    children: [
      {
        path: '/error/404',
        name: 'error_404',
        meta: {
          title: '404',
          parentPath: '/error'
        },
        component: () => import('@/views/error/404.vue')
      },
      {
        path: '/error/500',
        name: 'error_500',
        meta: {
          title: '500',
          parentPath: '/error'
        },
        component: () => import('@/views/error/500.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'index',
    meta: { title: '首页' },
    redirect: '/index',
    component: Main,
    children: [
      {
        path: '/index',
        name: 'homepage',
        meta: {
          hidden: false,
          title: '首页',
          parentPath: '/',
          permission: ['view']
        },
        component: () => import('@/views/index/home.vue')
      },
      // {
      //   path: '/myInfo',
      //   name: 'myInfo',
      //   meta: {
      //     hidden: true,
      //     title: '我的信息',
      //     parentPath: '/',
      //     permission: ['view'],
      //   },
      //   component: () => import('@/views/index/MyInfo.vue'),
      // },
      {
        path: '/index/pwd',
        name: 'pwd',
        meta: {
          hidden: true,
          title: '修改密码',
          parentPath: '/',
          permission: ['view']
        },
        component: () => import('@/views/index/password.vue')
      },
      {
        path: '/index/mobile',
        name: 'changeMobile',
        meta: {
          hidden: true,
          title: '修改手机号',
          parentPath: '/',
          permission: ['view']
        },
        component: () => import('@/views/index/mobile.vue')
      },
      {
        path: '/index/destroy',
        name: 'cancel',
        meta: {
          hidden: true,
          title: '注销账号',
          parentPath: '/',
          permission: ['view']
        },
        component: () => import('@/views/index/destroy.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [...asyncRoutes, ...constantRoutes]
})

export default router
